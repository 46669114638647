(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  var passiveSupported = false;

  try {
    var options = Object.defineProperty({}, 'passive', {
      get: function get() {
        passiveSupported = true;
      }
    });
    window.addEventListener('test', null, options);
  } catch (err) {}

  var DIRECTION = null;

  function direction() {
    if (DIRECTION === null) {
      DIRECTION = getComputedStyle(document.body).direction;
    }

    return DIRECTION;
  }

  function isRTL() {
    return direction() === 'rtl';
  }

  $(function () {
    /*
    // Touch Click
    */
    function touchClick(elements, callback) {
      elements = $(elements);
      var touchStartData = null;

      var onTouchstart = function onTouchstart(event) {
        var originalEvent = event.originalEvent;

        if (originalEvent.touches.length !== 1) {
          touchStartData = null;
          return;
        }

        touchStartData = {
          target: originalEvent.currentTarget,
          touch: originalEvent.changedTouches[0],
          timestamp: new Date().getTime()
        };
      };

      var onTouchEnd = function onTouchEnd(event) {
        var originalEvent = event.originalEvent;

        if (!touchStartData || originalEvent.changedTouches.length !== 1 || originalEvent.changedTouches[0].identity !== touchStartData.touch.identity) {
          return;
        }

        var timestamp = new Date().getTime();
        var touch = originalEvent.changedTouches[0];
        var distance = Math.abs(Math.sqrt(Math.pow(touchStartData.touch.screenX - touch.screenX, 2) + Math.pow(touchStartData.touch.screenY - touch.screenY, 2)));

        if (touchStartData.target === originalEvent.currentTarget && timestamp - touchStartData.timestamp < 500 && distance < 10) {
          callback(event);
        }
      };

      elements.on('touchstart', onTouchstart);
      elements.on('touchend', onTouchEnd);
      return function () {
        elements.off('touchstart', onTouchstart);
        elements.off('touchend', onTouchEnd);
      };
    } // call this in touchstart/touchend event handler


    function preventTouchClick() {
      var onClick = function onClick(event) {
        event.preventDefault();
        document.removeEventListener('click', onClick);
      };

      document.addEventListener('click', onClick);
      setTimeout(function () {
        document.removeEventListener('click', onClick);
      }, 100);
    }
    /*
    // topbar dropdown
    */


    $('.topbar-dropdown__btn').on('click', function () {
      $(this).closest('.topbar-dropdown').toggleClass('topbar-dropdown--opened');
    });
    document.addEventListener('click', function (event) {
      $('.topbar-dropdown').not($(event.target).closest('.topbar-dropdown')).removeClass('topbar-dropdown--opened');
    }, true);
    touchClick(document, function (event) {
      $('.topbar-dropdown').not($(event.target).closest('.topbar-dropdown')).removeClass('topbar-dropdown--opened');
    });
    /*
    // search suggestions
    */

    $('.search').each(function (index, element) {
      var xhr;
      var search = $(element);
      var categories = search.find('.search__categories');
      var input = search.find('.search__input');
      var suggestions = search.find('.search__suggestions');
      var button = search.find('.search__button--type--submit');
      var url = location.origin + '/search';

      var outsideClick = function outsideClick(event) {
        // If the inner element still has focus, ignore the click.
        if ($(document.activeElement).closest('.search').is(search)) {
          return;
        }

        search.not($(event.target).closest('.search')).removeClass('search--suggestions-open');
      };

      var setSuggestion = function setSuggestion(data) {
        if (data && data.products) {
          var html = '<ul class="suggestions__list">';
          data.products.forEach(function (product) {
            html += getSuggestionItemTemplate(product);
          });
          html += '</ul>';
          suggestions.html(html);
        }

        if (data && data.products && data.products.length) {
          search.addClass('search--has-suggestions');
        } else {
          search.removeClass('search--has-suggestions');
        }
      };

      search.on('focusout', function () {
        setTimeout(function () {
          if (document.activeElement === document.body) {
            return;
          } // Close suggestions if the focus received an external element.


          search.not($(document.activeElement).closest('.search')).removeClass('search--suggestions-open');
        }, 10);
      });
      input.on('input', function () {
        if (xhr) {
          // Abort previous AJAX request.
          xhr.abort();
        }

        if (input.val()) {
          // YOUR AJAX REQUEST HERE.
          xhr = $.ajax({
            method: 'post',
            url: location.origin + '/index.php?route=product/search/live',
            data: {
              search: input.val()
            },
            success: function success(data) {
              xhr = null;
              setSuggestion(data);
            }
          });
        } else {
          // Remove suggestions.
          setSuggestion('');
        }
      });
      input.on('focus', function () {
        search.addClass('search--suggestions-open');
      });
      categories.on('focus', function () {
        search.removeClass('search--suggestions-open');
      });
      button.on('click', function () {
        var value = input.val();

        if (value) {
          location.href = url + '?search=' + encodeURIComponent(value);
        }
      });
      input.on('keyup', function (e) {
        if (e.key !== 'Enter') {
          return;
        }

        var value = input.val();

        if (value) {
          location.href = url + '?search=' + encodeURIComponent(value);
        }
      });
      document.addEventListener('click', outsideClick, true);
      touchClick(document, outsideClick);

      if (input.is(document.activeElement)) {
        input.trigger('focus').trigger('input');
      }
    });

    var getSuggestionItemTemplate = function getSuggestionItemTemplate(product) {
      return "\n                <li>\n                    <a class=\"suggestions__item\" href=\"".concat(product.href, "\">\n                        <div class=\"suggestions__item-image product-image\">\n                        <div class=\"product-image__body\">\n                            <img class=\"product-image__img\" src=\"").concat(product.thumb, "\" alt=\"").concat(product.name, "\">\n                        </div>\n                        </div>\n            \n                        <div class=\"suggestions__item-info\">\n                            <span class=\"suggestions__item-name\">").concat(product.name, "</span>\n                        </div>\n                        <div class=\"suggestions__item-price\">").concat(product.price, "</div>\n            \n                        <div class=\"suggestions__item-actions\">\n                            <button\n                                type=\"button\"\n                                title=\"Add to cart\"\n                                class=\"btn btn-primary btn-sm btn-svg-icon\"\n                            >\n                                <svg width=\"16px\" height=\"16px\">\n                                    <use xlink:href=\"catalog/view/theme/default/assets/images/sprite.svg#cart-16\"></use>\n                                </svg>\n                            </button>\n                        </div>\n                    </a>\n                </li>\n            ");
    };
    /*
    // mobile search
    */


    var mobileSearch = $('.mobile-header__search');

    if (mobileSearch.length) {
      $('.indicator--mobile-search .indicator__button').on('click', function () {
        if (mobileSearch.is('.mobile-header__search--open')) {
          mobileSearch.removeClass('mobile-header__search--open');
        } else {
          mobileSearch.addClass('mobile-header__search--open');
          mobileSearch.find('input')[0].focus();
        }
      });
      mobileSearch.find('.search__button--type--close').on('click', function () {
        mobileSearch.removeClass('mobile-header__search--open');
      });
      document.addEventListener('click', function (event) {
        if (!$(event.target).closest('.indicator--mobile-search, .mobile-header__search').length) {
          mobileSearch.removeClass('mobile-header__search--open');
        }
      }, true);
    }
    /*
    // nav-links
    */


    function CNavLinks(element) {
      this.element = $(element);
      this.items = this.element.find('.nav-links__item');
      this.currentItem = null;
      this.element.data('navLinksInstance', this);
      this.onMouseenter = this.onMouseenter.bind(this);
      this.onMouseleave = this.onMouseleave.bind(this);
      this.onGlobalTouchClick = this.onGlobalTouchClick.bind(this);
      this.onTouchClick = this.onTouchClick.bind(this); // add event listeners

      this.items.on('mouseenter', this.onMouseenter);
      this.items.on('mouseleave', this.onMouseleave);
      touchClick(document, this.onGlobalTouchClick);
      touchClick(this.items, this.onTouchClick);
    }

    CNavLinks.prototype.onGlobalTouchClick = function (event) {
      // check that the click was outside the element
      if (this.element.not($(event.target).closest('.nav-links')).length) {
        this.unsetCurrentItem();
      }
    };

    CNavLinks.prototype.onTouchClick = function (event) {
      if (event.cancelable) {
        var targetItem = $(event.currentTarget);

        if (this.currentItem && this.currentItem.is(targetItem)) {
          return;
        }

        if (this.hasSubmenu(targetItem)) {
          event.preventDefault();

          if (this.currentItem) {
            this.currentItem.trigger('mouseleave');
          }

          targetItem.trigger('mouseenter');
        }
      }
    };

    CNavLinks.prototype.onMouseenter = function (event) {
      this.setCurrentItem($(event.currentTarget));
    };

    CNavLinks.prototype.onMouseleave = function () {
      this.unsetCurrentItem();
    };

    CNavLinks.prototype.setCurrentItem = function (item) {
      this.currentItem = item;
      this.currentItem.addClass('nav-links__item--hover');
      this.openSubmenu(this.currentItem);
    };

    CNavLinks.prototype.unsetCurrentItem = function () {
      if (this.currentItem) {
        this.closeSubmenu(this.currentItem);
        this.currentItem.removeClass('nav-links__item--hover');
        this.currentItem = null;
      }
    };

    CNavLinks.prototype.hasSubmenu = function (item) {
      return !!item.children('.nav-links__submenu').length;
    };

    CNavLinks.prototype.openSubmenu = function (item) {
      var submenu = item.children('.nav-links__submenu');

      if (!submenu.length) {
        return;
      }

      submenu.addClass('nav-links__submenu--display'); // calculate max height

      var submenuTop = submenu.offset().top - $(window).scrollTop();
      var viewportHeight = window.innerHeight;
      var paddingBottom = 20;
      submenu.css('maxHeight', viewportHeight - submenuTop - paddingBottom + 'px');
      submenu.addClass('nav-links__submenu--open'); // megamenu position

      if (submenu.hasClass('nav-links__submenu--type--megamenu')) {
        var container = submenu.offsetParent();
        var containerWidth = container.width();
        var megamenuWidth = submenu.width();

        if (isRTL()) {
          var itemPosition = containerWidth - (item.position().left + item.width());
          var megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));
          submenu.css('right', megamenuPosition + 'px');
        } else {
          var _itemPosition = item.position().left;

          var _megamenuPosition = Math.round(Math.min(_itemPosition, containerWidth - megamenuWidth));

          submenu.css('left', _megamenuPosition + 'px');
        }
      }
    };

    CNavLinks.prototype.closeSubmenu = function (item) {
      var submenu = item.children('.nav-links__submenu');

      if (!submenu.length) {
        return;
      }

      submenu.removeClass('nav-links__submenu--display');
      submenu.removeClass('nav-links__submenu--open');
      submenu.css('maxHeight', '');

      if (submenu && submenu.is('.nav-links__submenu--type--menu')) {
        var submenuInstance = submenu.find('> .menu').data('menuInstance');

        if (submenuInstance) {
          submenuInstance.unsetCurrentItem();
        }
      }
    };

    $('.nav-links').each(function () {
      new CNavLinks(this);
    });
    /*
    // menu
    */

    function CMenu(element) {
      this.element = $(element);
      this.container = this.element.find('> .menu__submenus-container');
      this.items = this.element.find('> .menu__list > .menu__item');
      this.currentItem = null;
      this.element.data('menuInstance', this);
      this.onMouseenter = this.onMouseenter.bind(this);
      this.onMouseleave = this.onMouseleave.bind(this);
      this.onTouchClick = this.onTouchClick.bind(this); // add event listeners

      this.items.on('mouseenter', this.onMouseenter);
      this.element.on('mouseleave', this.onMouseleave);
      touchClick(this.items, this.onTouchClick);
    }

    CMenu.prototype.onMouseenter = function (event) {
      var targetItem = $(event.currentTarget);

      if (this.currentItem && targetItem.is(this.currentItem)) {
        return;
      }

      this.unsetCurrentItem();
      this.setCurrentItem(targetItem);
    };

    CMenu.prototype.onMouseleave = function () {
      this.unsetCurrentItem();
    };

    CMenu.prototype.onTouchClick = function (event) {
      var targetItem = $(event.currentTarget);

      if (this.currentItem && this.currentItem.is(targetItem)) {
        return;
      }

      if (this.hasSubmenu(targetItem)) {
        preventTouchClick();
        this.unsetCurrentItem();
        this.setCurrentItem(targetItem);
      }
    };

    CMenu.prototype.setCurrentItem = function (item) {
      this.currentItem = item;
      this.currentItem.addClass('menu__item--hover');
      this.openSubmenu(this.currentItem);
    };

    CMenu.prototype.unsetCurrentItem = function () {
      if (this.currentItem) {
        this.closeSubmenu(this.currentItem);
        this.currentItem.removeClass('menu__item--hover');
        this.currentItem = null;
      }
    };

    CMenu.prototype.getSubmenu = function (item) {
      var submenu = item.find('> .menu__submenu');

      if (submenu.length) {
        this.container.append(submenu);
        item.data('submenu', submenu);
      }

      return item.data('submenu');
    };

    CMenu.prototype.hasSubmenu = function (item) {
      return !!this.getSubmenu(item);
    };

    CMenu.prototype.openSubmenu = function (item) {
      var submenu = this.getSubmenu(item);

      if (!submenu) {
        return;
      }

      submenu.addClass('menu__submenu--display'); // calc submenu position

      var menuTop = this.element.offset().top - $(window).scrollTop();
      var itemTop = item.find('> .menu__item-submenu-offset').offset().top - $(window).scrollTop();
      var viewportHeight = window.innerHeight;
      var paddingY = 20;
      var maxHeight = viewportHeight - paddingY * 2;
      submenu.css('maxHeight', maxHeight + 'px');
      var submenuHeight = submenu.height();
      var position = Math.min(Math.max(itemTop - menuTop, 0), viewportHeight - paddingY - submenuHeight - menuTop);
      submenu.css('top', position + 'px');
      submenu.addClass('menu__submenu--open');

      if (isRTL()) {
        var submenuLeft = this.element.offset().left - submenu.width();

        if (submenuLeft < 0) {
          submenu.addClass('menu__submenu--reverse');
        }
      } else {
        var submenuRight = this.element.offset().left + this.element.width() + submenu.width();

        if (submenuRight > $('body').innerWidth()) {
          submenu.addClass('menu__submenu--reverse');
        }
      }
    };

    CMenu.prototype.closeSubmenu = function (item) {
      var submenu = this.getSubmenu(item);

      if (submenu) {
        submenu.removeClass('menu__submenu--display');
        submenu.removeClass('menu__submenu--open');
        submenu.removeClass('menu__submenu--reverse');
        var submenuInstance = submenu.find('> .menu').data('menuInstance');

        if (submenuInstance) {
          submenuInstance.unsetCurrentItem();
        }
      }
    };

    $('.menu').each(function () {
      new CMenu($(this));
    });
    /*
    // indicator (dropcart, drop search)
    */

    function CIndicator(element) {
      this.element = $(element);
      this.dropdown = this.element.find('.indicator__dropdown');
      this.button = this.element.find('.indicator__button');
      this.trigger = null;
      this.element.data('indicatorInstance', this);

      if (this.element.hasClass('indicator--trigger--hover')) {
        this.trigger = 'hover';
      } else if (this.element.hasClass('indicator--trigger--click')) {
        this.trigger = 'click';
      }

      this.onMouseenter = this.onMouseenter.bind(this);
      this.onMouseleave = this.onMouseleave.bind(this);
      this.onTransitionend = this.onTransitionend.bind(this);
      this.onClick = this.onClick.bind(this);
      this.onGlobalClick = this.onGlobalClick.bind(this); // add event listeners

      this.element.on('mouseenter', this.onMouseenter);
      this.element.on('mouseleave', this.onMouseleave);
      this.dropdown.on('transitionend', this.onTransitionend);
      this.button.on('click', this.onClick);
      $(document).on('click', this.onGlobalClick);
      touchClick(document, this.onGlobalClick);
      this.element.find('.search__input').on('keydown', function (event) {
        var ESC_KEY_CODE = 27;

        if (event.which === ESC_KEY_CODE) {
          var instance = $(this).closest('.indicator').data('indicatorInstance');

          if (instance) {
            instance.close();
          }
        }
      });
    }

    CIndicator.prototype.toggle = function () {
      if (this.isOpen()) {
        this.close();
      } else {
        this.open();
      }
    };

    CIndicator.prototype.onMouseenter = function () {
      this.element.addClass('indicator--hover');

      if (this.trigger === 'hover') {
        this.open();
      }
    };

    CIndicator.prototype.onMouseleave = function () {
      this.element.removeClass('indicator--hover');

      if (this.trigger === 'hover') {
        this.close();
      }
    };

    CIndicator.prototype.onTransitionend = function (event) {
      if (this.dropdown.is(event.target) && event.originalEvent.propertyName === 'visibility' && !this.isOpen()) {
        this.element.removeClass('indicator--display');
      }
    };

    CIndicator.prototype.onClick = function (event) {
      if (this.trigger !== 'click') {
        return;
      }

      if (event.cancelable) {
        event.preventDefault();
      }

      this.toggle();
    };

    CIndicator.prototype.onGlobalClick = function (event) {
      // check that the click was outside the element
      if (this.element.not($(event.target).closest('.indicator')).length) {
        this.close();
      }
    };

    CIndicator.prototype.isOpen = function () {
      return this.element.is('.indicator--open');
    };

    CIndicator.prototype.open = function () {
      this.element.addClass('indicator--display');
      this.element.width(); // force reflow

      this.element.addClass('indicator--open');
      this.element.find('.search__input').focus();
      var dropdownTop = this.dropdown.offset().top - $(window).scrollTop();
      var viewportHeight = window.innerHeight;
      var paddingBottom = 20;
      this.dropdown.css('maxHeight', viewportHeight - dropdownTop - paddingBottom + 'px');
    };

    CIndicator.prototype.close = function () {
      this.element.removeClass('indicator--open');
    };

    CIndicator.prototype.closeImmediately = function () {
      this.element.removeClass('indicator--open');
      this.element.removeClass('indicator--display');
    };

    $('.indicator').each(function () {
      new CIndicator(this);
    });
    /*
    // departments, sticky header
    */

    $(function () {
      /*
      // departments
      */
      var CDepartments = function CDepartments(element) {
        var self = this;
        element.data('departmentsInstance', self);
        this.element = element;
        this.container = this.element.find('.departments__submenus-container');
        this.linksWrapper = this.element.find('.departments__links-wrapper');
        this.body = this.element.find('.departments__body');
        this.button = this.element.find('.departments__button');
        this.items = this.element.find('.departments__item');
        this.mode = this.element.is('.departments--fixed') ? 'fixed' : 'normal';
        this.fixedBy = $(this.element.data('departments-fixed-by'));
        this.fixedHeight = 0;
        this.currentItem = null;

        if (this.mode === 'fixed' && this.fixedBy.length) {
          this.fixedHeight = this.fixedBy.offset().top - this.body.offset().top + this.fixedBy.outerHeight();
          this.body.css('height', this.fixedHeight + 'px');
        }

        this.linksWrapper.on('transitionend', function (event) {
          if (event.originalEvent.propertyName === 'height') {
            $(this).css('height', '');
            $(this).closest('.departments').removeClass('departments--transition');
          }
        });
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onGlobalClick = this.onGlobalClick.bind(this);
        this.onMouseenter = this.onMouseenter.bind(this);
        this.onMouseleave = this.onMouseleave.bind(this);
        this.onTouchClick = this.onTouchClick.bind(this); // add event listeners

        this.button.on('click', this.onButtonClick);
        document.addEventListener('click', this.onGlobalClick, true);
        touchClick(document, this.onGlobalClick);
        this.items.on('mouseenter', this.onMouseenter);
        this.linksWrapper.on('mouseleave', this.onMouseleave);
        touchClick(this.items, this.onTouchClick);
      };

      CDepartments.prototype.onButtonClick = function (event) {
        event.preventDefault();

        if (this.element.is('.departments--open')) {
          this.close();
        } else {
          this.open();
        }
      };

      CDepartments.prototype.onGlobalClick = function (event) {
        if (this.element.not($(event.target).closest('.departments')).length) {
          if (this.element.is('.departments--open')) {
            this.close();
          }
        }
      };

      CDepartments.prototype.setMode = function (mode) {
        this.mode = mode;

        if (this.mode === 'normal') {
          this.element.removeClass('departments--fixed');
          this.element.removeClass('departments--open');
          this.body.css('height', 'auto');
        }

        if (this.mode === 'fixed') {
          this.element.addClass('departments--fixed');
          this.element.addClass('departments--open');
          this.body.css('height', this.fixedHeight + 'px');
          $('.departments__links-wrapper', this.element).css('maxHeight', '');
        }
      };

      CDepartments.prototype.close = function () {
        if (this.element.is('.departments--fixed')) {
          return;
        }

        var content = this.element.find('.departments__links-wrapper');
        var startHeight = content.height();
        content.css('height', startHeight + 'px');
        this.element.addClass('departments--transition').removeClass('departments--open');
        content.height(); // force reflow

        content.css('height', '');
        content.css('maxHeight', '');
        this.unsetCurrentItem();
      };

      CDepartments.prototype.closeImmediately = function () {
        if (this.element.is('.departments--fixed')) {
          return;
        }

        var content = this.element.find('.departments__links-wrapper');
        this.element.removeClass('departments--open');
        content.css('height', '');
        content.css('maxHeight', '');
        this.unsetCurrentItem();
      };

      CDepartments.prototype.open = function () {
        var content = this.element.find('.departments__links-wrapper');
        var startHeight = content.height();
        this.element.addClass('departments--transition').addClass('departments--open');
        var documentHeight = document.documentElement.clientHeight;
        var paddingBottom = 20;
        var contentRect = content[0].getBoundingClientRect();
        var endHeight = Math.min(content.height(), documentHeight - paddingBottom - contentRect.top);
        content.css('height', startHeight + 'px');
        content.height(); // force reflow

        content.css('maxHeight', endHeight + 'px');
        content.css('height', endHeight + 'px');
      };

      CDepartments.prototype.onMouseenter = function (event) {
        var targetItem = $(event.currentTarget);

        if (this.currentItem && targetItem.is(this.currentItem)) {
          return;
        }

        this.unsetCurrentItem();
        this.setCurrentItem(targetItem);
      };

      CDepartments.prototype.onMouseleave = function () {
        this.unsetCurrentItem();
      };

      CDepartments.prototype.onTouchClick = function (event) {
        var targetItem = $(event.currentTarget);

        if (this.currentItem && this.currentItem.is(targetItem)) {
          return;
        }

        if (this.hasSubmenu(targetItem)) {
          preventTouchClick();
          this.unsetCurrentItem();
          this.setCurrentItem(targetItem);
        }
      };

      CDepartments.prototype.setCurrentItem = function (item) {
        this.unsetCurrentItem();
        this.currentItem = item;
        this.currentItem.addClass('departments__item--hover');
        this.openSubmenu(this.currentItem);
      };

      CDepartments.prototype.unsetCurrentItem = function () {
        if (this.currentItem) {
          this.closeSubmenu(this.currentItem);
          this.currentItem.removeClass('departments__item--hover');
          this.currentItem = null;
        }
      };

      CDepartments.prototype.getSubmenu = function (item) {
        var submenu = item.find('> .departments__submenu');

        if (submenu.length) {
          this.container.append(submenu);
          item.data('submenu', submenu);
        }

        return item.data('submenu');
      };

      CDepartments.prototype.hasSubmenu = function (item) {
        return !!this.getSubmenu(item);
      };

      CDepartments.prototype.openSubmenu = function (item) {
        var submenu = this.getSubmenu(item);

        if (submenu) {
          submenu.addClass('departments__submenu--open');
          var documentHeight = document.documentElement.clientHeight;
          var paddingBottom = 20;

          if (submenu.hasClass('departments__submenu--type--megamenu')) {
            var submenuTop = submenu.offset().top - $(window).scrollTop();
            submenu.css('maxHeight', documentHeight - submenuTop - paddingBottom + 'px');
          }

          if (submenu.hasClass('departments__submenu--type--menu')) {
            submenu.css('maxHeight', documentHeight - paddingBottom - Math.min(paddingBottom, this.body.offset().top - $(window).scrollTop()) + 'px');
            var submenuHeight = submenu.height();
            var itemTop = this.currentItem.offset().top - $(window).scrollTop();
            var containerTop = this.container.offset().top - $(window).scrollTop();
            submenu.css('top', Math.min(itemTop, documentHeight - paddingBottom - submenuHeight) - containerTop + 'px');
          }
        }
      };

      CDepartments.prototype.closeSubmenu = function (item) {
        var submenu = item.data('submenu');

        if (submenu) {
          submenu.removeClass('departments__submenu--open');

          if (submenu.is('.departments__submenu--type--menu')) {
            submenu.find('> .menu').data('menuInstance').unsetCurrentItem();
          }
        }
      };

      var departmentsElement = $('.departments');
      var departments = departmentsElement.length ? new CDepartments(departmentsElement) : null;
      /*
      // sticky nav-panel
      */

      var nav = $('.nav-panel--sticky');

      if (nav.length) {
        var mode = nav.data('sticky-mode') ? nav.data('sticky-mode') : 'alwaysOnTop'; // one of [alwaysOnTop, pullToShow]

        var media = matchMedia('(min-width: 992px)');
        var departmentsMode = departments ? departments.mode : null;
        var stuck = false;
        var shown = false;
        var scrollDistance = 0;
        var scrollPosition = 0;

        var positionWhenToFix = function positionWhenToFix() {
          return 0;
        };

        var positionWhenToStick = function positionWhenToStick() {
          return 0;
        };

        var closeAllSubmenus = function closeAllSubmenus() {
          if (departments) {
            departments.closeImmediately();
          }

          $('.nav-links').data('navLinksInstance').unsetCurrentItem();
          $('.indicator').each(function () {
            $(this).data('indicatorInstance').closeImmediately();
          });
        };

        var show = function show() {
          nav.addClass('nav-panel--show');
          shown = true;
          $(document).trigger('stroyka.header.sticky.show');
        };

        var hide = function hide() {
          nav.removeClass('nav-panel--show');
          shown = false;
          $(document).trigger('stroyka.header.sticky.hide');
        };

        var onScroll = function onScroll() {
          var scrollDelta = window.pageYOffset - scrollPosition;

          if (scrollDelta < 0 !== scrollDistance < 0) {
            scrollDistance = 0;
          }

          scrollPosition = window.pageYOffset;
          scrollDistance += scrollDelta;

          if (window.pageYOffset > positionWhenToStick()) {
            if (!stuck) {
              nav.addClass('nav-panel--stuck');
              nav.css('transitionDuration', '0s');

              if (mode === 'alwaysOnTop') {
                show();
              }

              nav.height(); // force reflow

              nav.css('transitionDuration', '');
              stuck = true;

              if (departments && departmentsMode === 'fixed') {
                departments.setMode('normal');
              }

              closeAllSubmenus();
            }

            if (mode === 'pullToShow') {
              var distanceToShow = 10; // in pixels

              var distanceToHide = 25; // in pixels

              if (scrollDistance < -distanceToShow && !nav.hasClass('nav-panel--show')) {
                show();
              }

              if (scrollDistance > distanceToHide && nav.hasClass('nav-panel--show')) {
                hide();
                closeAllSubmenus();
              }
            }
          } else if (window.pageYOffset <= positionWhenToFix()) {
            if (stuck) {
              nav.removeClass('nav-panel--stuck');
              stuck = false;
              hide();

              if (departments && departmentsMode === 'fixed') {
                departments.setMode('fixed');
              }

              closeAllSubmenus();
            }
          }
        };

        var onMediaChange = function onMediaChange() {
          if (media.matches) {
            scrollDistance = 0;
            scrollPosition = window.pageYOffset;
            var navPanelTop = nav.offset().top;
            var navPanelBottom = navPanelTop + nav.outerHeight();
            var departmentsBottom = departments ? departments.body.offset().top + departments.body.outerHeight() : 0;

            if (departments && departmentsMode === 'fixed' && departmentsBottom > navPanelBottom) {
              positionWhenToFix = positionWhenToStick = function positionWhenToStick() {
                return departmentsBottom;
              };
            } else {
              if (mode === 'alwaysOnTop') {
                positionWhenToFix = positionWhenToStick = function positionWhenToStick() {
                  return navPanelTop;
                };
              } else {
                positionWhenToFix = function positionWhenToFix() {
                  return shown ? navPanelTop : navPanelBottom;
                };

                positionWhenToStick = function positionWhenToStick() {
                  return navPanelBottom;
                };
              }
            }

            window.addEventListener('scroll', onScroll, passiveSupported ? {
              passive: true
            } : false);
            onScroll();
          } else {
            if (stuck) {
              nav.removeClass('nav-panel--stuck');
              stuck = false;
              hide();

              if (departments && departmentsMode === 'fixed') {
                departments.setMode('fixed');
              }

              closeAllSubmenus();
            }

            window.removeEventListener('scroll', onScroll, passiveSupported ? {
              passive: true
            } : false);
          }
        };

        if (media.addEventListener) {
          media.addEventListener('change', onMediaChange);
        } else {
          media.addListener(onMediaChange);
        }

        onMediaChange();
      }
      /*
      // sticky mobile-header
      */


      var mobileHeader = $('.mobile-header--sticky');
      var mobileHeaderPanel = mobileHeader.find('.mobile-header__panel');

      if (mobileHeader.length) {
        var _mode = mobileHeader.data('sticky-mode') ? mobileHeader.data('sticky-mode') : 'alwaysOnTop'; // one of [alwaysOnTop, pullToShow]


        var _media = matchMedia('(min-width: 992px)');

        var _stuck = false;
        var _shown = false;
        var _scrollDistance = 0;
        var _scrollPosition = 0;
        var _positionWhenToFix = 0;
        var _positionWhenToStick = 0;

        var _show = function _show() {
          mobileHeader.addClass('mobile-header--show');
          _shown = true;
          $(document).trigger('stroyka.header.sticky.show');
        };

        var _hide = function _hide() {
          mobileHeader.removeClass('mobile-header--show');
          _shown = false;
          $(document).trigger('stroyka.header.sticky.hide');
        };

        var _onScroll = function _onScroll() {
          var scrollDelta = window.pageYOffset - _scrollPosition;

          if (scrollDelta < 0 !== _scrollDistance < 0) {
            _scrollDistance = 0;
          }

          _scrollPosition = window.pageYOffset;
          _scrollDistance += scrollDelta;

          if (window.pageYOffset > _positionWhenToStick) {
            if (!_stuck) {
              mobileHeader.addClass('mobile-header--stuck');
              mobileHeaderPanel.css('transitionDuration', '0s');

              if (_mode === 'alwaysOnTop') {
                _show();
              }

              mobileHeader.height(); // force reflow

              mobileHeaderPanel.css('transitionDuration', '');
              _stuck = true;
            }

            if (_mode === 'pullToShow') {
              if (window.pageYOffset > _positionWhenToFix) {
                var distanceToShow = 10; // in pixels

                var distanceToHide = 25; // in pixels

                if (_scrollDistance < -distanceToShow && !_shown) {
                  _show();
                }

                if (_scrollDistance > distanceToHide && _shown) {
                  _hide();
                }
              } else if (_shown) {
                _hide();
              }
            }
          } else if (window.pageYOffset <= _positionWhenToFix) {
            if (_stuck) {
              mobileHeader.removeClass('mobile-header--stuck');
              _stuck = false;

              _hide();
            }
          }
        };

        var _onMediaChange = function _onMediaChange() {
          if (!_media.matches) {
            _scrollDistance = 0;
            _scrollPosition = window.pageYOffset;
            _positionWhenToFix = mobileHeader.offset().top;
            _positionWhenToStick = _positionWhenToFix + (_mode === 'alwaysOnTop' ? 0 : mobileHeader.outerHeight());
            window.addEventListener('scroll', _onScroll, passiveSupported ? {
              passive: true
            } : false);

            _onScroll();
          } else {
            if (_stuck) {
              mobileHeader.removeClass('mobile-header--stuck');
              mobileHeader.removeClass('mobile-header--show');
              _stuck = false;
              _shown = false;
              $(document).trigger('stroyka.header.sticky.hide');
            }

            window.removeEventListener('scroll', _onScroll, passiveSupported ? {
              passive: true
            } : false);
          }
        };

        if (_media.addEventListener) {
          _media.addEventListener('change', _onMediaChange);
        } else {
          _media.addListener(_onMediaChange);
        }

        _onMediaChange();
      }
    });
    /*
    // offcanvas cart
    */

    (function () {
      var body = $('body');
      var cart = $('.dropcart--style--offcanvas');

      if (cart.length === 0) {
        return;
      }

      function cartIsHidden() {
        return window.getComputedStyle(cart[0]).visibility === 'hidden';
      }

      function showScrollbar() {
        body.css('overflow', '');
        body.css('paddingRight', '');
      }

      function hideScrollbar() {
        var bodyWidth = body.width();
        body.css('overflow', 'hidden');
        body.css('paddingRight', body.width() - bodyWidth + 'px');
      }

      function open() {
        hideScrollbar();
        cart.addClass('dropcart--open');
      }

      function close() {
        if (cartIsHidden()) {
          showScrollbar();
        }

        cart.removeClass('dropcart--open');
      }

      $('[data-open="offcanvas-cart"]').on('click', function (event) {
        if (!event.cancelable) {
          return;
        }

        event.preventDefault();
        open();
      });
      cart.find('.dropcart__backdrop, .dropcart__close').on('click', function () {
        close();
      });
      cart.on('transitionend', function (event) {
        if (cart.is(event.target) && event.originalEvent.propertyName === 'visibility' && cartIsHidden()) {
          showScrollbar();
        }
      });
    })();
  });
})(jQuery);

},{}]},{},[1]);
